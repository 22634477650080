const INITIAL_STATE = () => ({
  favourites: [], // Legacy Guest Favourites
  favouriteIds: [],
})

const state = INITIAL_STATE()

const actions = {
  addFavourite({ state, commit }, favourite) {
    const favourites = [favourite, ...state.favourites]

    commit("SET_FAVOURITES", favourites)
  },
  removeFavourite({ state, commit }, listingID) {
    const favourites = state.favourites.filter((favourite) => favourite.id != listingID)

    commit("SET_FAVOURITES", favourites)
  },
  reset({ commit }) {
    commit("RESET")
  },
}

const mutations = {
  SET_FAVOURITES(state, data) {
    state.favourites = data
  },
  RESET(state) {
    Object.assign(state, INITIAL_STATE())
  },
}

const getters = {
  favouriteIds: (state) => {
    const legacyFavIds = state.favourites.map((favourite) => favourite.id)

    return legacyFavIds.concat(state.favouriteIds)
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
